import { saveAs } from 'file-saver'
import { MessageBox, Message } from 'element-ui'


export const showToast = msg => {
    if(isNative()) Android.showToast(msg)
    else {
        Message({
            message: msg || 'Error',
            type: 'error',
            duration: 5 * 1000
        })
    }
}
export const getToken = _=>{
    return isNative() ? Android.getToken() : 'qcesQmUh4RWKQQTu4qyZXKzgLZ4s7cTYa1Mqv83WjhXlMlrgZTE0KlXGJfpM'
}
export const closePage = _=> {
    if(isNative()) Android.goBack()
    else console.log('关闭app')
}
export const exportTransactionList = (data, filename, bookType)=>{
    if(isNative()) {
        // Android.saveExcel('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+data, `${filename}.${bookType}`)
        Android.saveExcel(data, `${filename}.${bookType}`)
    }
    else {
        saveAs(new Blob([s2ab(data)], {
            type: "application/octet-stream"
        }), `${filename}.${bookType}`);
    }
}
export const goToLogin = () => {
    if(isNative()) {

    }
    else {
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
            confirmButtonText: 'Re-Login',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then(() => {
            store.dispatch('user/resetToken').then(() => {
            location.reload()
            })
        })
    }
}

export const isNative = () => {
    return (typeof Android !='undefined') ? true : false
}