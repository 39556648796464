import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';

import '@/utils/utils.js'

import Vconsole from 'vconsole'
new Vconsole()
// process.NODE_ENV === 'development' ? new Vconsole() : ''

Vue.use(ElementUI, {locale});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
