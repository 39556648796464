import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: '/reconciliation'
  },
  {
    path: "/reconciliation",
    name: "Reconciliation",
    component: () => import("../views/reconciliation/index.vue"),
    meta: {
      title: 'Cashier Reconciliation'
    },
  },
  {
    path: "/transaction",
    name: "Transaction",
    meta: {title: 'Trans.List'},
    component: () =>
      import("../views/transaction/index.vue"),
  },
  {
    path: "/transaction/detail",
    name: "TransactionDetai",
    meta: {title: 'Tran.Detail'},
    component: () =>
      import("../views/transaction/detail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
