<template>
  <div id="app">
    <div class="header fixed">
      <el-button size="mini" @click="goBack">Back</el-button>POS > {{ pageTitle }}
    </div>
    <keep-alive :include="'Transaction'">
      <router-view style="padding: 60px 48px 0px"/>
    </keep-alive>
  </div>
</template>
<script>
import { closePage } from './utils/nativeBrige';
export default {
  data() {
    return {
      pageTitle: '',
    }
  },
  watch: {
    $route(to, from){
      this.pageTitle = to.meta.title
    }
  },
  mounted () {
    console.log('app mounted', window.location.href);
    window.firstHref = window.location.href;

    console.log(window.innerHeight, document.body.clientHeight)
  },
  methods: {
    goBack(){
      console.log(window.location.href, window.firstHref)
      if(window.location.href == window.firstHref) {
        closePage()
      }
      else this.$router.go(-1)
    }
  }
}
</script>

<style>
body,html{
  height: calc(100% - 10px);
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  font-size: 28px;
  overflow: hidden;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.el-form-item__label {
  font-size: 18px !important;
}
.el-dialog__body {
  font-size: 20px !important;
}
.el-button, .el-table {
  font-size: 18px !important;
}
.el-input {
  font-size: 20px !important;
}
.el-radio__label, .el-checkbox__label{
  font-size: 18px !important;
}
.el-checkbox__inner, .el-radio__inner {
  width: 21px !important;
  height: 21px !important;
}
.el-checkbox__inner::after{
  height: 12px !important;
  width: 6px !important;
  left: 7px !important;
}
.el-radio__inner::after{
  width: 8px !important;
  height: 8px !important;
}
</style>
<style lang="scss" scoped>
.header{
  background-color: #fe5100;
  padding: 8px 15px;
  color: #fff;
  margin-bottom: 10px;
  
  .el-button{
    color: #fe5100;
    width: 100px;
    margin-right: 20px;
  }
}
.fixed {
  position: fixed;
  top: 0px;
  left:0px;
  width: 100%;
  z-index: 2001;
  display: flex;
  align-items: center;
}
</style>
